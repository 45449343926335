


import {Divider, Stack} from "@mui/material";

import Tailer from "../components/tailer";
import Counter from "../components/counter";
import Countpanel from "../components/countpanel";
import IntroGraphic from "../components/introGraphic";
import Motor from "../components/motor";
import Caro from "../components/carosel";

import Slogan from "../components/slogan";
import SLogo from "../components/singleLogo";
import MailIcon from "../components/mail";
import Bubble from "../components/qBubble";
import Cubes from "../components/cubes";
import IconSet from "../components/iconset";
import Ologo from "../components/Ologo";




const Intro = ({auth , theme}) =>{
    return(
        <Stack orientation ="column" spacing={0} className="  " >
           

        <div id="intro" className={`  flex  2xl:h-screen md:h-screen xs:h-auto  grid 2xl:grid-cols-12  sm:grid-cols-1 2xl:gap-1 md-gap-1 2xl:w-6/6 xs:w-12/12  2xl:p-5 md:p-0 xs:p-2   ${theme.tailwind_bg_primary} `}>
        
       
  <Slogan theme={theme}   />
  
        <div className={` mx-1 my-5   2xl:py-5 md:p-5  xs-p-1 2xl:col-span-5 md:col-span-12 xs:col-span-9 ${theme.tailwind_bg_primary} hover:text-slate-800  ${theme.tailwind_text_dark}  text-xl  md:text-lg   `} >
       
    <div className="  trasition-background duration-700 hover:bg-slate-50  hover:p-5 hover:shadow-xl text-justify hover:rounded-xl    " >



     

     <div className={ `p-5 text-center flex flex-col 2xl:text-5xl  xs:text-xl    ${theme.tailwind_text_main}  font-bold `} >
  
      Why ADVANCED SOLUTIONS?
</div>
<div className="xs:w-1/2 m-auto">
Strategically Positioned for Excellence: Nestled in the heart of Jubail and Yanbu, the industrial hubs of Saudi Arabia, ADVANCED SOLUTIONS is uniquely poised to deliver swift and efficient engineering, construction, and maintenance services. Our proximity to key project zones allows us to provide rapid client support, saving you valuable time and money. This strategic advantage reduces project downtime and eliminates reliance on external resources beyond Yanbu and the Kingdom of Saudi Arabia.

</div>
</div>


   
          
        </div>


  </div>
 
  <IconSet theme={theme} />
        
      
        
 

      <Countpanel theme={theme} />
      <div className="flex justify-center text-3xl  text-center w-full  2xl:gap-12  sm:gap-8 h-auto">
<Caro theme={theme}/>
</div>
      <Tailer theme={theme} />
      

        </Stack>
    
    )
}
export {
    Intro
}