import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { Cookie } from "@mui/icons-material";

const Coo = () => {

function getLocation() {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(success, showPosition, showError);
        console.log(navigator.language)
        
      
    } else {
        console.log("Geolocation is not supported by this browser.");
    }
    

}
function success(position) {

    Cookies.set("location", JSON.stringify({lat: position.coords.latitude , long:position.coords.longitude}), { expires: 7 , path : '/'});
    Cookies.set("language", navigator.language, { expires: 7 , path : '/'});
    Cookies.set("timezone", Intl.DateTimeFormat().resolvedOptions().timeZone, { expires: 7 , path : '/'});
    Cookies.set("browser", navigator.userAgent, { expires: 7 , path : '/'});
    Cookies.set("screen", `${window.screen.width} x ${window.screen.height}`, { expires: 7 , path : '/'});
    Cookies.set("color", window.screen.colorDepth, { expires: 7 , path : '/'});
    Cookies.set("cookieEnabled", navigator.cookieEnabled, { expires: 7 , path : '/'});
    Cookies.set("online", navigator.onLine, { expires: 7 , path : '/'});
    //navigator.clipboard.readText().then(text => { Cookies.set("bluetooth", text.toString() , { expires: 7 , path : '/'})})
    Cookies.set("connection", navigator.connection.effectiveType , { expires: 7 , path : '/'});
      
    
    
   
    
    
    const location = JSON.parse(Cookies.get("location"));
    console.log(location.long);
}

function showPosition(position) {
    console.log('Latitude: ' + position.coords.latitude);
    console.log('Longitude: ' + position.coords.longitude);
}

console.log(navigator.connection)



function showError(error) {
    switch(error.code) {
        case error.PERMISSION_DENIED:
            console.log("User denied the request for Geolocation.");
            break;
        case error.POSITION_UNAVAILABLE:
            console.log("Location information is unavailable.");
            break;
        case error.TIMEOUT:
            console.log("The request to get user location timed out.");
            break;
        case error.UNKNOWN_ERROR:
            console.log("An unknown error occurred.");
            break;
    }
}



    const [thecockie, setTheCookie] = useState(Cookies.get("cookies"));
    Cookies.set("browser", navigator.userAgent, { expires: 7  , path : '/'});
   




    //const cookie = Cookies.get("cookies");


    const acceptCookies = () => {
        Cookies.set("cookies", "accepted", { expires: 7 , path : '/'});
       getLocation();
          const cookie = Cookies.get("cookies");
           
        setTheCookie(cookie);

    }
    const reject = (e) => {
           
        //e.preventDefault();
    
        
         Cookies.set("cookies", "rejected", { expires: 7 });
          const cookie = Cookies.get("cookies");
        setTheCookie(cookie);
    }
        
 


    return (
        <div>
            {thecockie !== "accepted" && (
                <div className="cookie h-screen w-screen">
                <div className="flex flex-col mx-auto py-4  m-auto relative  w-1/2 z-40  h-1/2 bg-slate-200 items-center justify-center  shadow-2xl 2xl:w-1/2 2xl:h-1/2 xs:w-full m-auto p-5 xs:h-full 2xl:top-1/4 xs:top-0">
                    <i>
                        We use cookies to ensure you get the best experience on our website
                        <br/>
                         
                        
                    </i>
                    <a href="/privacy">Learn more</a>
                    <div className="flex flex-row">
                    <Button className="m-5" variant="outlined" color="error" size="small" onClick={reject}>reject</Button>
                    <Button className="m-5" color="success" variant="outlined" size="small" onClick={acceptCookies}>Accept</Button>
                    
                    </div>
                </div>
                </div>
            )
            
            }
            {
                thecockie === "rejected" && (
                    <div className="cookie h-screen w-screen">
                <div className="flex flex-col mx-auto py-4 cookies   w-1/2 z-40  h-1/2 bg-slate-200 items-center  shadow-2xl">
                    
Cookies rejected
                    
                    <a href="/privacy">Learn more</a>
                    <div className="flex flex-row">
                
                    <Button className="m-5" color="success" variant="contained" size="small" onClick={acceptCookies}>change you mind</Button>
                    
                    </div>
                </div>
                </div>
                )
            }

            
        </div>
    );
}

export default Coo;