
import Navbutton from "./navbutton"
import Logo from "./logo"

import Cookies from 'js-cookie'    

import Json from "./fetchJson";
import Searcher from "./searchfield";
import { Button } from "react-bootstrap";
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import Slogan from "./slogan";
import SLogo from "./singleLogo";   
import { Divider, Input } from "@mui/material";
import OLogo from "./Ologo";

const Navbar = ({auth , theme}) =>{
    const data = Json({url: "../data.json"})
    console.log(data)
    const logout = () => {
        Cookies.remove('token')
        window.location.reload()
    }
    return (

        <div className="  z-40 flex flex-end   xs:flex-col  sticky w-full">
            <div className="  w-full  flex justify-end   px-5 bg-slate-950 2xl:relative md:relative xs:absolute  " ><OLogo theme={theme} width={50}/></div>
            
    <div className={`px-5 flex    w-full  my-0 py-0  justify-end bg-slate-950 2xl:relative md:relative xs:absolute  `} >

               
  
        <nav class=" flex   ">
  <div class="max-w-screen-xl flex  justify-between  ">
   
    <button data-collapse-toggle="navbar-default" type="button" class="inline-flex items-center p-2 w-12  justify-center  md:hidden  " aria-controls="navbar-default" aria-expanded="false" onMouseDown={
        (e) => {
            e.preventDefault()
            const navbar = document.getElementById('navbar-default')
            if(navbar.classList.contains('hidden')){
                navbar.classList.remove('hidden')
            }else{
                navbar.classList.add('hidden')
            }
        }
        
        

    }>
        <span class="sr-only">Open main menu</span>
        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
            <path stroke={theme.svg_color} stroke-linecap="" stroke-linejoin="round" stroke-width="1" d="M1 1h15M1 7h15M1 13h15"/>
        </svg>
    </button>
    <div class="  hidden w-full md:block md:w-auto   " id="navbar-default">
      <ul class=" flex    md:p-0    md:flex-row  md:mt-0  xs:flex-col border-b-3  ">
 
{
    data &&  data[0]['menus'].map((item , key) => {
        return (
            
            <Navbutton key={key} text={item.name} link ={item.url} id={item.id} auth={auth} protect={item.protected} theme={theme}/>
        )
    }

    ) 

}
    {

auth ? <LogoutIcon fontSize="large" className="flex m-auto " onClick={logout} /> : <a href='/login' className={ `flex m-auto ${theme.tailwind_text_dark} `} ><LoginIcon   fontSize="large"  /></a>
     }

      </ul>
    </div>
  </div>
</nav>

    </div>
    
</div>
    
   
    )

}
export default Navbar