

import {
    BrowserRouter as Router,
    Route, Routes
} from 'react-router-dom';
import { Intro } from '../pages/start';
import AccessDenied from '../pages/accessdenied';
import Login from '../pages/login';
import Register from '../pages/register';
import Projectspage from '../pages/projects';
import  Dashboard from '../pages/dashboard';

import ContactForm from '../pages/contact';
import TermsAndConditions from '../pages/tc';
import PrivacyPolicy from '../pages/pp';
import Team from '../pages/team';
import Careers from '../pages/careers';
import Start from '../pages/1_start';
import NServices from '../pages/2_services';
import NContact from '../pages/5_contact';

import NCareers from '../pages/3_careers';
import NTeam from '../pages/4_team';
import NProjects from '../pages/6_projects';

const MyRouter = ({auth , theme}) => {
    return (
        <div className='grid grid-cols-1 gap-1'>

<Router>
<Routes>
<Route path='/' element={<Start /> }/>
<Route path='*' element={<AccessDenied theme={theme} />} />
<Route path='/login' element={auth  ? <Intro className='z-40'  theme={theme} /> :<Login/>}  />
<Route path='/register' element={<Register auth={auth} theme={theme}/>} />
<Route path='/projects' element={<NProjects theme ={theme} />} />
<Route path='/dashboard' element={auth ? <Dashboard auth={auth}/> : <Login/>} />

<Route path='/services' element={<NServices />} />
<Route path='/contact' element={<NContact theme ={theme}/>} />
<Route path='/tc' element={<TermsAndConditions theme={theme} />} />
<Route path='/privacy-policy' element={<PrivacyPolicy theme={theme} />} />
<Route path='/team' element={<NTeam/>} />
<Route path='/careers' element={<NCareers theme={theme} />} />
<Route path='/start' element={<ContactForm theme ={theme}/>} />

</Routes>
</Router>


   
   
   </div>)
}
export default MyRouter