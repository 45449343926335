import * as React from 'react';

import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
const Tnode = ({ text , orientation }) => {
  return (
       <TimelineItem>
        <TimelineSeparator>
          <TimelineDot>
            <div className="z-40 bg-slate-50  rounded-full"></div>
            </TimelineDot>
          
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent className='z-40'>
            
            <div className={` transition-all duration-700 border   w-full my-auto 2xl:p-8 2xl:text-xl   bg-slate-50 shadow-2xl rounded-xl text-center ${orientation}`}>
           
{text}
            </div>
           
            </TimelineContent>
      </TimelineItem>
  );
}
export default Tnode