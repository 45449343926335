import { Percent } from "@mui/icons-material";
import { useState } from "react";
const Percentage = ({theme , height , deg=180} ) => {   
    const [color, setColor] = useState(theme.icon_color)
   
    const [animate, setAnimate] = useState(false)
    function changeColor(){
        setColor("black")
    }
    

    return (
       <div  onClick={changeColor}   className= {`flex justify-center m-24   ${animate ? 'animate-bounce animate-infinite' : ''} `} >
           <svg width={height} height={height} fill={color} stroke={color} opacity={.1} strokeWidth={.05} strokeOpacity={.5} viewBox="0 0 48 48" id="a" xmlns="http://www.w3.org/2000/svg"><path id="b" class="e" d="M11.8418,5.5l29.3752,24.7074-14.712-3.7391L11.8418,5.5Z"/><path id="c" class="e" d="M40.4349,31.7959L6.783,26.6149l20.5773,9.2378,13.0746-4.0568Z"/><path id="d" class="e" d="M40.606,33.5799l-20.7483,8.9201,12.2926-.8065,8.4557-8.1136Z"/></svg>
        </div>
    )
}
export default Percentage;