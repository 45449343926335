import { Divider } from "@mui/material";
import SLogo from "./singleLogo";
import { red } from '@mui/material/colors';
import Dnb from "./dnb";
const Footer = ({auth , theme}) => {
    return (
        <div className={ ` flex flex-col w-full items-center  justify-center   `} >
           

<hr className={`m-1 w-full  text-white `}/>      
 <div className={ `" flex 2xl:flex-row xs:flex-col  text-slate-300 justify-center  " `}>
          
       
   
        <div className="flex 2xl:flex-col  xs:flex-row p-2 items-center m-auto">
              
<SLogo   text={true} width={80} theme={theme}/> 
        
        </div>
        
        
       
                <img className="flex  flex-col items-baseline m-auto  border-x px-3 py-5 " src="./img/seal.jpg" width={400}/> 
                <Dnb/>

        <div className=" m-5 mx-auto  p-3 text-center  text-slate-500  ">
         © 2024 <br></br>
         Advanced Solutions  <br></br>
         Yanbu Al Sinaiyah 9128, Saudi Arabia<br></br>
         Radwa 8 District, Al Wadi St. <br></br> 
         Building No. 3729 (Namlah Bldg.), Office No. 1
         

</div>



         </div>
         < a href="/tc" className="m-4 text-slate-500 no-underline text-sm" >Terms & Conditions</a>
         </div>
    );
    }

export default Footer;