import StartpageCard from "./startpageCard"
import { Parallax } from "react-scroll-parallax"
import Caro from "./carosel"

const Tailer =({theme}) => {
    return (
        <div id='tailer' className={ `flex flex-col m-auto items-center justify-center  w-10/12 `} >


       <Parallax translateY={[50, -100]} >
            <div id='tailer' className="z-0 m-0 p-1 grid xl:grid-cols-3 gap-4  ">
             
<StartpageCard theme={theme} title="Why Choose Advanced Solutions?" avatartext="JU" description="At Advanced Solutions, we pride ourselves on being a top-tier provider of electrical, instrumentation, automation, and control system solutions in the Kingdom of Saudi Arabia. Our commitment to quality, safety, and efficiency ensures that your projects are completed on time and within budget. Located strategically in Jubail and Yanbu, we offer expedited services that save you time and money, reducing your dependency on external resources. Join us and experience world-class standards tailored to meet your needs in the Oil & Gas, Petrochemical, Power, and other industrial sectors." image="img\AdobeStock_402968103.jpeg"/>


<StartpageCard theme={theme} title="Comprehensive Services for Your Industry" avatartext="i" description="Advanced Solutions offers a full spectrum of services to meet your industrial needs. From process instrumentation and control systems to advanced industrial software and process safety systems, we cover it all. Our turnkey solutions encompass design, engineering, quality, safety, construction, and planning management. Whether it's electrical systems, fire and gas safety, or HVAC systems, we ensure every aspect of your project is handled with the utmost precision and care. Trust us to deliver unparalleled expertise and support for your critical infrastructure." image="img\AdobeStock_611241348.jpeg"/>


<StartpageCard theme={theme} title="Join Our Dynamic Team" description="Are you passionate about engineering and innovation? At Advanced Solutions, we are always on the lookout for talented individuals to join our team. We value commitment, high standards, and a dedication to excellence. As part of our team, you'll work on cutting-edge projects in various sectors, including Oil & Gas, Petrochemicals, and Power Plants. Enjoy a collaborative work environment that prioritizes safety and professional growth. Start your career with us and contribute to shaping the future of industrial solutions in Saudi Arabia." image="img\image - 2024-10-30T192521.335.JPEG"/>
    
</div>
</Parallax>
  </div>
    )
}
export default Tailer