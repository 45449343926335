
const OLogo= ({width  , style , onClick , text = false , theme }) =>{
    //const color = `${theme.svg_color}`
    return(

       <div id='card' className={`flex flex-col p-3  items-center justify-center m-auto" ${style} `} >
        
<div className=" flex flex-col justify-center items-center m-auto">
   
 <svg className=" " stroke={theme.icon_color} id="Ebene_1" data-name="Ebene 2" width={width} onClick={onClick}
    height={width} xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 140 134" >
            

  <path transform=" translate(0,0)" fill="#ffffff" d="M72.06.76c.73.05,1.47.09,2.2.15,1.59.14,3.19.29,4.8.44,0,.74.02,1.44,0,2.12-.1,3.18.88,5.95,3.1,8.25,1.51,1.57,3.37,2.65,5.26,3.69,2.39,1.31,4.93,2.2,7.65,2.56,3.1.4,6.09-.02,9.02-1.08,3.15-1.14,5.82-2.96,7.91-5.61.03-.05.07-.08.11-.12,2.97,1.48,12.04,10.35,14.82,14.5-.1.07-.21.15-.31.23-3.67,2.62-6.24,6.04-7.2,10.49-1.28,5.98.24,11.26,4.45,15.68,3.13,3.3,7.06,5.05,11.57,5.52,1.33.14,2.68.12,4.07.17.56,6.6.37,13.23-1.12,19.75-1.24.12-2.45.18-3.64.35-4.02.57-7.61,2.08-10.46,5.04-2.89,3-4.71,6.56-5.23,10.72-.38,3.02.24,5.88,1.59,8.6.4.8.85,1.58,1.33,2.34.48.75,1.02,1.45,1.57,2.22-1.25,1.32-2.52,2.65-3.82,4.02-.05-.08-.09-.12-.1-.17-2.31-5.06-4.64-10.12-6.93-15.19-6.24-13.77-12.48-27.55-18.72-41.32-5.25-11.59-10.5-23.18-15.75-34.77-.52-1.14-1.07-1.46-2.32-1.43-1.46.03-2.91.05-4.37.05-2.03,0-4.07-.01-6.11-.02-.47,0-.93-.03-1.4-.02-.59.02-.98.35-1.22.89-.62,1.37-1.24,2.73-1.86,4.1-3.45,7.6-6.89,15.21-10.35,22.81-5.13,11.28-10.26,22.56-15.4,33.85-4.36,9.59-8.73,19.17-13.1,28.75-.47,1.03-.99,2.04-1.5,3.12-1.46-1.52-2.89-3.01-4.32-4.49.52-.73,1.05-1.42,1.52-2.14,1.44-2.18,2.47-4.52,2.88-7.11.43-2.75.07-5.39-.93-7.96-1.17-2.98-2.84-5.64-5.31-7.74-2.33-1.97-5.04-3.13-8.01-3.74-1.55-.32-3.12-.48-4.7-.49-.22,0-.3-.07-.34-.28-.35-2.12-.75-4.23-1.04-6.36-.19-1.38-.22-2.79-.32-4.18,0-.08-.03-.15-.05-.23v-4.23c.02-.07.04-.15.05-.23.06-1.02.11-2.04.18-3.06.03-.42.08-.83.12-1.22.07-.02.1-.03.12-.03h.41c2.22-.01,4.4-.23,6.56-.77,3.21-.81,6.04-2.3,8.36-4.7,2.13-2.22,3.51-4.87,4.44-7.77.7-2.2.87-4.46.52-6.74-.63-4.15-2.59-7.6-5.66-10.43-.68-.63-1.42-1.2-2.16-1.81,4.15-5.57,9.07-10.38,14.71-14.51.06.07.1.13.14.18.95,1.11,2.03,2.08,3.21,2.94,4.7,3.44,9.89,4.58,15.59,3.26,3.46-.8,6.54-2.39,9.42-4.41,2.57-1.81,4.19-4.25,4.59-7.4.17-1.3.13-2.63.18-3.95,0-.14.02-.28.03-.47,1.66-.15,3.29-.3,4.93-.44.7-.06,1.4-.1,2.1-.14h4.3,0,0Z"/>
  <path transform=" translate(0,0)" fill="#ffffff"  d="M110.42,119.05c-1.47-1.36-2.89-2.73-3.74-4.64-3.72-8.3-7.51-16.58-11.28-24.86-4.39-9.63-8.8-19.26-13.2-28.89-3.78-8.28-7.57-16.55-11.35-24.82-.21-.45-.44-.89-.71-1.42-10.99,24.07-21.94,48.06-32.9,72.08h52.33c-6.47-14.19-12.93-28.37-19.42-42.62-4.86,10.62-9.69,21.17-14.55,31.79h6.49c.32,0,.56-.1.73-.34.17-.25.34-.51.47-.79,1.98-4.34,3.96-8.68,5.94-13.02.26-.56.54-1.1.83-1.7,3.18,7.02,6.34,13.99,9.51,21.01h-33.29c7.93-17.36,15.84-34.68,23.77-52.06,9.75,21.39,19.49,42.74,29.25,64.16-.42-.03-.77-.06-1.13-.09-1.53-.11-3.06-.21-4.59-.34-.91-.07-1.4-.51-1.63-1.39-.23-.91-.58-1.18-1.5-1.21h-.38c-12.64,0-25.28,0-37.93,0-4.81,0-9.63.05-14.44.07-.58,0-1.16.02-1.74.02-.71-.01-1.17.3-1.46.95-1.28,2.87-2.59,5.74-3.88,8.6-.03.07-.07.14-.11.23-.05-.02-.1-.03-.13-.05-1.25-1.01-2.5-2.01-3.73-3.04-.55-.46-.64-.97-.34-1.64.97-2.15,1.93-4.31,2.92-6.46,5.25-11.48,10.5-22.95,15.74-34.44,7.02-15.41,14.02-30.83,21.04-46.25.36-.8.72-1.59,1.09-2.39.1-.22.22-.42.34-.63.46-.76,1.11-1.07,1.99-.88.21.05.46.02.67-.03,1.49-.35,2.63.03,3.45,1.34.52.84.95,1.76,1.36,2.67,6.35,14.01,12.69,28.02,19.05,42.03,6.7,14.78,13.43,29.54,20.14,44.31.1.23.21.46.28.69.17.54.05.9-.38,1.24-1.17.92-2.35,1.83-3.55,2.77h0Z"/>
  <path transform=" translate(0,0)" fill="#ffffff"  d="M82.9,115.63c-1.55,1-2.46,2.51-3.54,3.87-.53.67-1.07,1.33-1.61,1.98-.26.31-.6.43-1.02.43-5.45-.01-10.9-.01-16.35,0-.36,0-.51-.12-.64-.44-.74-1.89-1.5-3.78-2.26-5.66-.02-.05-.03-.11-.06-.19h25.48Z"/>
  <path transform=" translate(0,0)" fill="#ffffff" d="M78.24,133.15c-7.47.93-14.84.84-22.17-1.04.5-.82.98-1.62,1.47-2.41.34-.55.7-1.09,1.04-1.64.24-.38.59-.55,1.03-.57h16.07c.7,0,1.04.25,1.23.93.42,1.46.83,2.93,1.25,4.39.03.1.05.2.08.35h0Z"/>
</svg></div>
{
  text ?  <div ><div className=" uppercase   font-thin justify-center 2xl:flex xs:hidden  ">
                <div className=" text-3xl font-bold text-slate-900 tracking-tighter  ">Advanced</div><div className={ ` text-3xl tracking-wider ${theme.tailwind_text_main} `}>Solutions</div> 
                </div>
  
            <div className={`  2xl:flex xs:hidden  text-justify  text-center t ${theme.tailwind_text_dark} tracking-wide     `}>
                FOR MACHINERY AND EQUIPMENT CO. LTD.  
                </div> </div>:<></>
}

           </div>


   
    )

}

export default OLogo
