import { Divider } from "@mui/material";

import Button from '@mui/material/Button';
import { useState } from "react";
import SLogo from "./singleLogo";
import { orange } from '@mui/material/colors';
import { Parallax } from 'react-scroll-parallax';


const ServiceCard = ({ service , theme }) => {
    const [show, setShow] = useState(true)
    const showrest = (e) => {
        e.preventDefault()
        const longtext = document.getElementById(service.title)
        longtext.classList.remove('truncate')
        setShow(!show)
    }
    const hiderest = (e) => {
        e.preventDefault()
        const longtext = document.getElementById(service.title)
        longtext.classList.add('truncate')
        setShow(!show)
    }

    return (

      <div  className={ ` transition-all duration-700 shadow-2xl m-3   p-4 text-2xl  bg-white  border    text-center ${theme.tailwind_text_dark} items-center 2xl:col-span-4  xs:col-span-12 h-auto  ` }  >
        <div className="2xl:text-3xl  p-8  first-letter:font-bold " >
{service.title}
<Divider className="bg-slate-900 "/>
        </div>
        <img src={service.image} alt={service.title} className=" object-cover m-auto w-3/4 rounded-xl grayscale hover:grayscale-0  "/>
        <div id ={service.title} className={`${theme.tailwind_text_dark} text-justify 2xl:m-5 xs:mx-1 truncate ... `}  >   

{service.description}
        </div>
        <Button  className={ `border px-4 w-full btn text-${theme.danger}  btn-sm `}   onClick={ show ? showrest : hiderest} startIcon= {<SLogo theme={theme} width={20}   />
        }>
             {show ? ' show More' : 'show Less'}
        </Button>
        
        
   
</div>

    
    

    );
    }
    export default ServiceCard;