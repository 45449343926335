
import IconSet from "./iconset";
import Footer from "./footer";
import Theme from "./theme";
import Percentage from "./percentage";


const Frame = ({ children=[]  , auth  }) => {
     
    return (
        <div id="frame" className={ ` h-full 2xl:w-10/12  m-auto     `} >
            
        {children}
        <IconSet theme={Theme} />
         
        <Footer auth={auth} theme={Theme}/>

          <div className=" absolute top-0 right-0  -z-1  " >
  <Percentage theme={Theme} height={1500} />
  </div>
        </div>
    );
    }
    export default Frame;
