import Json from "./fetchJson"
import { useState } from "react";
import MainService from "./mainservice";
import Triangle from "./triangle";
import ServiceCard from "./servicecard";
import { Parallax } from "react-scroll-parallax";
import { Opacity } from "@mui/icons-material";

const  SecondaryServiceFetcher = ({theme}) => {
      const services = Json({url: "../services.json"})
    const [details, setDetails] = useState(true)

    
    return(
        
        <Parallax translateY={[8, 80]} opacity={[-.2,5]} >
        
        {
            services && services[0]["cats"].map((service) => {
                console.log(service)
                return (
                    
                    <div className="text-slate-950   ">
                    <div className={ `  justify-center mx-auto p-2 grid grid-cols-12 col-span-12 text-slate-50   w-12/12 font-thin   `} >
                    
                    <div className={ ` m-auto shadow-xl flex  flex-col h-auto text-xl   col-span-12 2xl:px-2 2xl:mx-48  md:px-2 md:mx-4 xs:px-8      items-center   text-justify  p-5      border-slate-950  bg-slate-950 w-4/12 `} >   {service.description} 
                    
                    </div>
                   
                

                    {
                        details && service.prods && service.prods.map((prod) => {
                            return (
                              
                                <ServiceCard  service={prod} theme ={theme}/>
                                
                            )
                        }
                    )


                    }
                    </div></div>
                )
            }
            )
        }

      </Parallax>
    )
}
export default SecondaryServiceFetcher;