const MainService = ({icon ,service}) => {
    return(
        <div className=" z-20 text-slate-50 bg-slate-950 shadow-2xl shadow-slate-800/[.9] transition-padding duration-700 h-auto  flex  flex-col justify-center     2xl:col-span-3 md:col-span-6 xs:col-span-12   px-0  m-auto  text-2xl w-11/12   " >
       <img className="w-full h-96 object-cover" src={service.image} alt="image" />
        <div className="text-xl  text-center p-5 ">{service.title}</div>
        <div className="text-justify text-xs p-3 ">{service.body}</div>
       
        </div>
    )
}
export default MainService;