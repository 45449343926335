import { amber, blue, blueGrey, brown, common, cyan, deepOrange, deepPurple, green, grey, indigo, lightBlue, lightGreen, lime, orange, pink, purple, red, teal, yellow } from '@mui/material/colors';
const Theme= {
    color_main: `red-600`,
    white : `slate-50`,
    svg_color: red["600"],
    icon_color: grey["A700"],
    danger: `red`,
    
    tailwind_text_light:`text-slate-50`,
    tailwind_text_main:`text-red-600`,
    tailwind_text_dark:"text-slate-800",
    hover_top_border: `border-b-gray-600`,
   
  
    //tailwind_bg_primary:`bg-slate-100`,
    tailwind_bg_secondary:"bg-slate-600"
  }
  export default Theme;