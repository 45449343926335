import Slogan from "./slogan";
import EmployeeIcon from "./employeeicon";
import Triangle from "./triangle";
import Percentage from "./percentage";
import ContactCard from "./contactCard";
const ContactIntro = ({theme, auth}) => {
    return (
        
        <div id="intro" className={`2xl:w-auto flex flex-col   text-slate-50     ${theme.tailwind_bg_primary} `}>
        
 
     
<div className="  2xl:text-9xl xs:text-3xl font-mdium text-center  font-thin text-slate-50  ">Contact us </div>
            <div className="   my-2 mx-auto 2xl:w-1/2 xs:w-full p-3 2xl:text-3xl text-justify font-thin  ">We'd love to hear from you! Whether you have a question, need assistance, or want to share feedback, our team is here to help. Please fill out the contact form below, and we'll get back to you as soon as possible.

To ensure we connect you with the right team, don't forget to select your country on our interactive map. This helps us tailor our response to your specific needs and location.

Your voice matters to us – reach out today!
            
    {/* <Triangle theme={theme} height={100} animate={false} /> */}
            
            </div>
          
           

  </div>
    )
}
export default ContactIntro;