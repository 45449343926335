import Youtube from "./youtube";
import Cubes from "./cubes";
import MailIcon from "./mail";
import Bubble from "./qBubble";
import Triangle from "./triangle";
import Facebook from "./facebook";
import Linkedin from "./linkedin";
const IconSet = ({theme}) => {
    return(
         <div className="z-40 transitions-all duration-700   absolute bottom-0  2xl:flex md:flex hover:px-8 hover:bg-red-700  xs:hidden left-1   bg-slate-300/[.9]" >
   {// <MailIcon height={30} theme={theme} />
}

   <Youtube height={30} />
   <Facebook height={30} />
   <Linkedin height={30} />
 

     </div>
    )
}
export default IconSet;