import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import Button from "react-bootstrap/Button";
import { grey, red } from '@mui/material/colors';
//import { useHistory } from 'react-router-dom';

import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import SLogo from '../components/singleLogo';
const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message , setMessage] = useState("Login to access secure area")
  const [messagestatus , setMessageStatus] = useState("text-info")
  const [loading , setLoading] = useState(false)


  //const history = useHistory();

    const handleSubmit = async (e) => {
    e.preventDefault();
      try{
        setLoading(true);
    const response = await fetch('http://192.168.1.164:5000/login', {
  
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({  username , password }),
    });

    if (!response.ok) {
      console.log('Login failed');
      setMessage('Login failed! Please try again.');
      setMessageStatus('text-danger')
      setPassword('');
      setUsername('');
      setLoading(false);
      return;

    }else{
    
    const result = await response.json();
    console.log(result);
    result.username ? Cookies.set('token', result.username) : console.log('Login failed');
    console.log(Cookies.get('token'));
   setLoading(false);
    window.location.reload();
    } 
    }
    catch(err){
       setMessage(err.message);
       setLoading(false);
    }
  };
    
   
useEffect(() => {
    console.log('useEffect');

    
}, [loading])
       
        
    

  return (
    <div id='intro' className=' m-auto flex items-center   bg-slate-100 flex flex-col text-center w-screen' >
       
   <div className=' transition-width duration-700 hover:shadow-2xl flex flex-col items-center hover:border p-24 '> 
    
         
      <div className='my-4 text-3xl'>ADV. SOL. Dashboard</div>
      {loading && <CircularProgress className='block'  />}
        
      <div className={ messagestatus}>{message}</div>
      
       
 
   
  
      <form onSubmit={handleSubmit}>
        <div className='flex flex-col justify-evenly  items-center'>
          <label>Username:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className='flex flex-col justify-evenly  items-center'>
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <Button  className='px-5 m-4 btn-secondary'  type="submit">Login</Button>
        
      </form>
       <a  href='/register'>Register or contact Admin</a> 
       
    </div>
    
    </div>
  );
};

export default Login;
