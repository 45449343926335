import { CardActionArea, CardActions, CardContent, Divider } from "@mui/material";


const ContactCard = ({button=true , theme}) => {
    return (
        <div className={`${theme.tailwind_bg_primary}   `}  >

        
        <div className={ `z-40  card uppercase transition-shadow duration-500 hover:shadow-2xl text-sm  2xl:w-9/12 m-auto  xs:w-auto    rounded-xl  leading-[.3rem] xs:leading-[1rem] 2xl:p-5  xs:p-1 text-left border  `}>
        

            
        <div className={`text-xl text-pink-600 font-bold`}>Advanced Solutions For Machinery & Equipment Co. Ltd.</div>
<Divider className="my-2 "/>
        <p>Yanbu Al Sinaiyah 9128, Saudi Arabia</p>
        <p>Radwa 8 District, Al Wadi St.</p>
        <p>Building No. 3729 (Namlah Bldg.), Office No. 1</p>
        <p>Zip Code: 46452</p>
<Divider className="my-4 "/>
        <p>Tel: +966 (012) 640 4800 ext-105 (Jeddah)</p>
        <p>Mob: +966 (53) 935 5899 (Jubail), Eng. Iyad Kameel; iyad.kameel@advantions.com</p>
        {// <p>Mob: +966 (50) 066 2333 (Yanbu), Mr. Abdullah Al-Solami; abdullah@advantions.com</p>
}
<div className="flex justify-evenly">
<p>info@advantions.com</p>
        <p>www.advantions.com</p>
      
</div>
<Divider className="my-4 bg-slate-900"/>
        
        {
                button ? <a className=" btn btn-lg m-auto col-6 border " href="/contact">Contact us</a> : null  
        }
        
        
      
      </div>
     
      </div>

    );
    }

export default ContactCard;