import { Train } from "@mui/icons-material";
import MailIcon from "./mail";
import Theme from "./theme";
import Badge from '@mui/joy/Badge';


const ProjectCard = ({ project , nummer }) => {
    return (
        <div className=" card  transition-shadow duration-700 border  2xl:col-span-6  col-span-12 ">
         

            
                
                <Badge  color='danger' size="lg" variant="solid" badgeContent={project.ProjectType} className="w-11/12 h-11/12  card-body items-center  my-3    ">
<div className=" card-body items-center m-auto  ">
                <h1 className={ ` font-black  border-b-8   p-2 m-auto text-2xl text-center justify-center ${Theme.tailwind_text_dark} `} > {`${nummer +1 }.`}</h1>
<h5 className="card-title p-5 ">{project.ProjectName}</h5>
 <p className="card-text m-auto  text-3xl text-center text-slate-700  p-1 ">{project.ContractType}</p>
                <p className={` card-text text-3xl text-center  ${Theme.tailwind_text_main}  p-2 `}>{project.EndUser}</p>
                <p className="card-text text-xl text-center text-slate-900 px-2">{project.Description}</p>
                <div className="flex justify-end absolute bottom-0  ">
                    
                {/* <MailIcon theme={Theme} height={50}  /> */}
                </  div>
            </div>

                </Badge>
               
        </div>
    );
}
export default ProjectCard;