const Youtube = ({theme , height}) => {   
    return (
         <div className="flex justify-center m-2">
           <svg fill="#000000" height={height} 
	 viewBox="0 0 511.147 511.147" >
<g transform="translate(1 1)">
	<g>
		<g>
			<path d="M505.027,146.2c0-1.707-5.12-39.253-21.333-57.173c-19.627-21.333-40.96-23.893-52.053-24.747h-3.413
				c-69.12-5.12-172.373-5.973-174.08-5.973c-0.853,0-104.96,0.853-173.227,7.68h-2.56C66.413,66.84,45.08,69.4,26.307,90.733
				C9.24,108.653,4.12,147.053,4.12,148.76c0,0-5.12,43.52-5.12,87.04v40.107c0,42.667,5.12,86.187,5.12,87.04
				c0,1.707,5.12,39.253,21.333,57.173c17.92,20.48,39.253,23.04,52.907,24.747c2.56,0,4.267,0,6.827,0.853
				c39.253,3.413,163.84,5.12,168.96,5.12c0.853,0,104.96,0,174.08-7.68h2.56c11.947-0.853,33.28-3.413,52.053-24.747
				c17.067-17.92,22.187-56.32,22.187-58.027c0,0,5.12-43.52,5.12-87.04V233.24C510.147,190.573,505.027,147.053,505.027,146.2z
				 M493.08,275.053c0,42.667-5.12,85.333-5.12,85.333c-1.707,9.387-6.827,36.693-17.92,48.64
				c-14.507,17.067-31.573,18.773-40.96,19.627h-2.56c-68.267,5.12-171.52,5.12-172.373,5.12s-128.853-1.707-166.4-5.973
				c-2.56,0-5.12-0.853-7.68-0.853c-11.947-0.853-29.013-3.413-42.667-18.773c-10.24-11.093-16.213-38.4-17.067-47.787
				c0-0.853-5.12-43.52-5.12-85.333v-40.107c0-42.667,5.12-85.333,5.12-85.333c0.853-9.387,6.827-36.693,17.067-48.64
				C52.76,83.907,68.973,82.2,79.213,81.347h2.56c68.267-5.12,171.52-5.973,172.373-5.973s104.107,0.853,172.373,5.973h3.413
				c9.387,0.853,26.453,2.56,40.96,19.627c10.24,11.947,15.36,39.253,17.067,48.64c0.853,1.707,5.12,44.373,5.12,85.333V275.053z"/>
			<path d="M360.813,255.427l-162.133-94.72c-2.56-1.707-5.973-1.707-8.533,0s-4.267,4.267-4.267,7.68V353.56
				c0,3.413,1.707,5.973,4.267,7.68c1.707,0.853,2.56,0.853,4.267,0.853s2.56,0,4.267-0.853l162.133-90.453
				c2.56-1.707,4.267-4.267,4.267-7.68S363.373,257.133,360.813,255.427z M202.947,339.053v-156.16l136.533,80.213L202.947,339.053z
				"/>
		</g>
	</g>
</g>
</svg>
        </div>
    )
}
export default Youtube;