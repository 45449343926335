import Json from "./fetchJson"
import MemberCard from "./MemberCard";

const Members =({theme}) => {
     const team = Json({url: "../data/members.json"})
    return (
        <div className={`w-full  ${theme.tailwind_bg_primary} `}>
<div className={ ` m-auto items-center grid grid-cols-12  gap-1 w-12/12 z-10`}>
                {
                    team && team.map((member) => {
                        return (
                            <MemberCard theme={theme} member={member} />
                        )
                    })
                }
            
            </div>
        </div>
   
   
    )
}   
export default Members;