import Slogan from "./slogan";
import EmployeeIcon from "./employeeicon";
import Triangle from "./triangle";
import Percentage from "./percentage";
import ProjectTask from "./projectTask";
import DiagramIcon from "./diagramIcon";
const ProjectsIntro = ({theme, auth}) => {
    return (
        
        <div id="intro" className={`z-30 w-12/12 m-auto flex flex-col   my-5 p-8  h-96  rounded-lg   ${theme.tailwind_bg_primary} `}>
          <div className={ `text-5xl ${theme.tailwind_text_dark} font-black `}>Projects Portal</div>
       <div className="z-30    ">
         <div className="flex p-5 transition-shadow duration-700 hover:shadow-2xl  z-30 2xl:w-1/2 items-center justify-center m-auto  w-full     2xl:text-xl  xs:text-sm  font-thin    ">
        <div>Welcome to our Projects Portal! Here, we showcase a diverse array of engineering and construction projects undertaken across various industries.
<hr></hr>
          Each project is tailored to meet the specific needs of our clients, ensuring efficient, reliable, and state-of-the-art solutions. Browse through to explore our commitment to innovation and excellence in every project we undertake.
        </div>
    
   
     </  div>



 
</div>



     
      

  </div>
    )
}
export default ProjectsIntro;