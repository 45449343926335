import { Parallax } from "react-scroll-parallax";
import Slogan from "./slogan";




const Intro = ({theme, auth}) => {
    return (
        
        <div id="intro" className={`xl:h-1/4 xs:h-2/12     `}>
        
       <Parallax translateY={[-150, 100]} opacity={[5,-1]} >
  <Slogan theme={theme}   />
</Parallax>


  </div>
    )
}
export default Intro;