import { Divider } from "@mui/material";
import Counter from "./counter";
import { Parallax } from "react-scroll-parallax";
const Countpanel = () => {
    return (
        
        <div className={ `grid grid-cols-12 w-full gap-3  justify-center  text-slate-100  2xl:px-48 md:px-8  xs:px-5  `} >
            
            
            <Parallax translateX={[-100, 50]} tagOuter="figure" className={`xl:text-7xl flex col-span-12 justify-center  uppercase 2xl:border-x-4 border-x-4 bg-slate-950 text-slate-100   m-auto  items-center 2xl:w-1/2 md:w-1/2 xs:w-full  xl:h-96 text-center py-5 my-5`} >
                interesting Statistics
               
                </Parallax>

           <Parallax rotateZ={[15, -15]} translateX={[-100, 50]} className="2xl:col-span-4  xs:col-span-10  bg-pink-500    ">
<Counter   count={8} title="On Going Projects"  suffix={" Projects"} /> 
           </Parallax>           
           <Parallax rotateZ={[15 , -15]} className="2xl:col-span-4   xs:col-span-10 bg-purple-700 ">
<Counter  count={148000} title="Working Hours" suffix=' Hours' /> 
           </Parallax>

            <Parallax rotateZ={[-15, 15]} translateX={[100, -50]} className="2xl:col-span-4  xs:col-span-10    bg-lime-500 ">
<Counter   count={86} title="Done Works"  suffix={"Projects"}/> 
           </Parallax>
                </div>
       
    );
}
export default Countpanel;