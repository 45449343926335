import { Avatar, CardActionArea, CardActions, CardContent, Divider ,CardMedia } from "@mui/material";

import { red } from "@mui/material/colors";
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import CallSharpIcon from '@mui/icons-material/CallSharp';
import SendSharpIcon from '@mui/icons-material/SendSharp';
import LanguageSharpIcon from '@mui/icons-material/LanguageSharp';
import AlternateEmailSharpIcon from '@mui/icons-material/AlternateEmailSharp';
import CardHeader from '@mui/material/CardHeader';






import Card from '@mui/material/Card';
import MoreVertIcon from '@mui/icons-material/MoreVert';



const MemberCard = ({auth, member}) => {
    return (
        <div className='card z-30 transition-padding duration-700  border  hover:shadow-2xl    hover:p-4 m-3  leading-[.5rem] text-left 2xl:col-span-4 md:col-span-4 xs:col-span-12'>
        <CardHeader
        avatar={
          <Avatar sx={{  bgcolor: red[500] }} aria-label="recipe">
            {member.firstname[0]}{member.lastname[0]}
          </Avatar>
        }
        
        title={member.firstname + ' ' + member.lastname}
        subheader={member.position}

      />
      <CardMedia
        component="img"
        sx={{ maxWidth:300,  objectFit: 'cover' }}
        image={member.image}
        className=" trasintion-padding duration-700  m-auto hover:p-12 rounded-full border   grayscale hover:grayscale-0"
      />
 <CardContent className="w-full m-auto ">
        <Typography className="w-full m-auto text-justify" variant="body2" color="text.secondary">
            {member.description}
        </Typography>
      </CardContent>
           
        
           
<div className="p-3">
 

    
      
        <CardActions className="p-5 mx-5 flex justify-between">
        
        <a href={`mailto:${member.email}`} className="flex flex-col text-slate-500 m-auto">
        <AlternateEmailSharpIcon fontSize="large" /></a>  
        <a href={`tel:${member.mobile}`} className="flex flex-col text-slate-500 m-auto ">
        <CallSharpIcon fontSize="large" /> </a> 
        <a href={`/${member.firstname}-${member.lastname}`} className="flex flex-col text-slate-500 m-auto ">
        <LanguageSharpIcon fontSize="large" /> </a> 
        </CardActions>
</div>

       
      
        
        
         { 
            // <a className="btn btn-sm m-auto col-6 btn-success" href="/contact">Contact us</a>
         }
       


        
           <CardActions >
           

           </CardActions>
           
      
      </div>

    );
    }

export default MemberCard;