import Slogan from "./slogan";
import EmployeeIcon from "./employeeicon";
import Triangle from "./triangle";
import Percentage from "./percentage";
const TeamIntro = ({theme, auth}) => {
    return (
        
        <div id="intro" className={` flex flex-col  2xl:h-screen md:h-screen xs:h-screen     ${theme.tailwind_bg_primary} `}>
          {/* <div className="w-full m-auto flex justify-center h-96" >
 <img  src="../img/teamHeader.png" className="h-full" />
          </div> */}
         
        
  <div className=" 2xl:flex relative 2xl:p-5 md:flex h-fit w-fit top-0  m-auto  ">
            
              
                <div className="z-20 hover:w-1/3   transition-w duration-300 hover:ease-in-out  relative w-1/2  right-48   m-auto  " >
                <img  width={300} className=" rounded-full border border-dashed  bg-red-500" src="./img/javed.jpeg" alt="team " />
                </div>
                  <div className="z-20 hover:w-1/2   transition-w duration-300 absolute w-1/3 left-24 border-dashed      m-3 p-2" >
                <img width={300}   className="bg-pink-500 rounded-full border  " src="./img/hossam.png" alt="team " />
                </div>
                <div className="z-20 hover:w-2/3  transition-w duration-300 absolute w-2/4 2xl:right-96 xs:right-48  top-24 border-dashed       m-3 p-2" >
                <img width={300} className="bg-green-700 rounded-full border " src="./img/ahmed.png" alt="team " />
                </div>
                <div className="z-20 hover:w-2/3  transition-w duration-300 absolute w-3/4 2xl:left-48 xs:right-0  top-24  border-dashed        m-3 p-2" >
                <img width={300} className="bg-blue-600 rounded-full border " src="./img/iyad.jpg" alt="team " />
                </div>
                  <div className="z-1 hover:w-1/2  transition-w duration-300 absolute w-5/12 left-80  top-8 border-dashed         m-3 p-2" >
                <img width={300} className="bg-indigo-400 rounded-full border " src="./img/aejaz.png" alt="team " />
                </div>
                <div className="z-20 hover:w-1/3  transition-w duration-300 absolute w-5/12 left-96 top-48 border-dashed         m-3 p-2" >
                <img width={300} className="bg-pink-900 rounded-full border " src="./img/gopa.jpg" alt="team " />
                </div>
                <div className="z-20 hover:w-1/3   transition-w duration-300 absolute w-1/2 left-12 top-48 border-dashed        m-3 p-2" >
                <img width={300} className=" bg-blue-300 rounded-full border " src="./img/frie.jpg" alt="team " />
                </div>
                


                   <div className="z-0 hover:w-1/3  transition-w duration-300 absolute w-2/4 2xl:left-12 xs:right-0  top-72  border-dashed        m-5 p-3" >
                <img width={300} className="bg-blue-600 rounded-full border " src="./img/jawed.jpg" alt="team " />
                </div>

                  <div className="z-1 hover:w-1/2  transition-w duration-300 absolute w-5/12 left-0  top-24 border-dashed         m-3 p-2" >
                <img width={300} className="bg-indigo-400 rounded-full border " src="./img/musa.jpg" alt="team " />
                </div>
                <div className="z-20 hover:w-1/3  transition-w duration-300 absolute w-5/12 -left-96 top-48 border-dashed         m-3 p-2" >
                <img width={300} className="bg-pink-900 rounded-full border " src="./img/tabrez.png" alt="team " />
                </div>
                <div className="z-20 hover:w-1/3   transition-w duration-300 absolute w-1/2 -left-24 top-48 border-dashed        m-3 p-2" >
                <img width={300} className=" bg-blue-300 rounded-full border " src="./img/juber.jpg" alt="team " />
                </div>
            
                   
            
        </div>
     
       
<div className="  text-9xl font-mdium text-center m-auto font-thin  ">Our Team</div>
            <div className="   my-5 mx-auto 2xl:w-1/4 xs:w-full p-3 text-3xl text-justify font-thin  ">Our team is a group of highly skilled professionals who are dedicated to providing the best service to our clients. We are committed to excellence and strive to exceed our clients' expectations.
            
 
            </div>

  </div>
    )
}
export default TeamIntro;