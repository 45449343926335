import Frame from "../components/frame"

import Intro from "../components/intro"
import CounterPanel from "../components/countpanel"
import Caro from "../components/carosel"
import Tailer from "../components/tailer"
import Theme from "../components/theme"
import Why from "../components/why"
const Start = () => {
    return (
        <Frame  coloring={Theme} children={[
            
            <Intro theme={Theme}  /> ,
            <Why  />, 
            
            <Caro   theme={Theme} />,
            <CounterPanel theme={Theme} />,
            <Tailer theme={Theme} />
        ]}
        />
          
    )
}
export default Start