import { Button, Card, CardContent, CardHeader, Divider,Avatar } from "@mui/material"
import { red, green } from "@mui/material/colors"
const StartpageCard = ({ title, description, image , avatarColor , avatartext , theme }) => {

    return (
    
 
        <div className="border transition-all duration-700 text-2xl   shadow-2xl  shadow-slate-500 bg-slate-50 "   >
          
          

         
            
            <CardHeader className="p-5 text-sm text-slate-950" title={title}
            
        
          
      
            />
          <img className="w-full h-48 object-cover" src={image} alt="card" />
            <CardContent>
            
           
                <div className={ `p-4 text-sm    text-slate-950 text-justify `}>
                   {description}
                </div>
            </CardContent>
    
  
        
            
              
            </div>
          

    );
    }

export default StartpageCard;