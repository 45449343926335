
import './App.css';
import Navbar from './components/navbar';
import AttributionIcon from '@mui/icons-material/Attribution';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import Tron from './components/tron';
import Footer from './components/footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ParallaxProvider } from 'react-scroll-parallax';
import { amber, blue, blueGrey, brown, common, cyan, deepOrange, deepPurple, green, grey, indigo, lightBlue, lightGreen, lime, orange, pink, purple, red, teal, yellow } from '@mui/material/colors';





import Cookies from 'js-cookie';

import Auth from './components/auth';
import MyRouter from './components/router';
import { Divider, Badge } from '@mui/material';
import SLogo from './components/singleLogo';
import Coo from './components/cookies_adv';
import Theme from './components/theme';

function App() {
  const auth = Cookies.get('token');  

  const theme= {
    color_main: `red-600`,
    svg_color: red["A700"],
    icon_color: grey["A700"],
    danger: `red`,
    
    tailwind_text_light:`text-slate-100`,
    tailwind_text_main:`text-red-600`,
    tailwind_text_dark:"text-slate-800",
    hover_top_border: `border-t-red-600`,
   
  
    tailwind_bg_primary:`bg-slate-100`,
    tawilwind_bg_secondary:`bg-red-600`,
  }
  
  return (
    
    <div className='bg-slate-950'>
   
      <ParallaxProvider>

<Auth children= { [
 

 <Navbar auth={auth } theme={theme}/> ,


 <MyRouter auth={auth }  theme={theme}/>,
 
 
]

} />
      
<Coo />
</ParallaxProvider>

    </div>
   

    
  );
}

export default App;
