import { Icon } from "@mui/material"
import Frame from "../components/frame"
import MainServiceFetcher from "../components/mainserviceFetcher"
import Theme from "../components/theme"
import IconSet from "../components/iconset"
import ServiceIntro from "../components/service_intro"
import SecondaryServiceFetcher from "../components/secondary_services"
import CareersIntro from "../components/careers_intro"
import Triangle from "../components/triangle"
import ContactCard from "../components/contactCard"
import TeamIntro from "../components/team_intro"
import Members from "../components/teammembers_fetcher"
const NTeam = () => {
  
    return (
        <Frame  children={[
            <TeamIntro theme={Theme} />,
            <Members theme={Theme} />,
       
              
            
            
           
        ]}
        />
          
    )
}
export default NTeam