import { OpenInFullOutlined } from "@mui/icons-material"
import React from "react"
import Banner from "./banner"
import { Button } from "@mui/material"



const Navbutton = ({text , link , id , auth , protect , theme}) =>{
const [info , setInfo] = React.useState(<></>)
const showinfo = (e) =>{
   
    e.preventDefault()  
    const intro = document.getElementById('intro')
    

    intro.classList.add('transparent')
    setInfo(<Banner menu_id={id} theme={theme} />)
    //setInfo(<a className="text-9xl info">hello</a>)
     //setInfo(<div className="info text-9xl" >{id}</div>)
}
const hideinfo = (e) =>{

e.preventDefault()
 const intro = document.getElementById('intro')

    intro.classList.remove('transparent') 
   
setInfo(null)
}
const hover_border_button_color = "hover:border-"+ theme.color_main.toString()

    return(

        
    <li className={ ` transition-color duration-700 flex     hover:border-b-8  hover:border-b-slate-50 ` } >
        {(!auth  && protect ===true) ? <></> :
               <a className={ `h-24 flex items-center   uppercase  px-3 text-slate-100 2xl:text-lg  md:text-lg sm:text-xs text-center no-underline `}  href= {link} 

   
   onMouseEnter={
    //showinfo
    console.log('show')
   }
  onMouseLeave={
//hideinfo
console.log('hide')
  }
   >
    {text}
{info}

 
    </a> 
        }
 

</li>
    
    )
} 

export default Navbutton
