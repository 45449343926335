import { Icon } from "@mui/material"
import Frame from "../components/frame"
import MainServiceFetcher from "../components/mainserviceFetcher"
import Theme from "../components/theme"
import IconSet from "../components/iconset"
import ServiceIntro from "../components/service_intro"
import SecondaryServiceFetcher from "../components/secondary_services"
import CareersIntro from "../components/careers_intro"
import Triangle from "../components/triangle"
import ContactCard from "../components/contactCard"
import CareerSection2 from "../components/career_section_2"
const NCareers = () => {
  
    return (
        <Frame  children={[
            
            <CareersIntro theme={Theme} />,
             <CareerSection2 theme={Theme} />,
            <ContactCard theme={Theme} />,
           
              
              
            
            
           
        ]}
        />
          
    )
}
export default NCareers