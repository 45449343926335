import React, { useState, useRef } from 'react';




import emailjs from '@emailjs/browser';
import { TextField , Input , Button} from '@mui/material';

import {Stack} from '@mui/material';


const Captcha = () => {




  const [captchaText, setCaptchaText] = useState('');
  const [userInput, setUserInput] = useState('');
  const canvasRef = useRef(null);


   const [isSubmitting, setIsSubmitting] = useState(false);
  const [stateMessage, setStateMessage] = useState(null);

  
    




  const sendEmail = (e) => {
   
    e.persist();
    e.preventDefault();
    setIsSubmitting(true);
    emailjs
      .sendForm(
        "service_moph2ff",
        "template_nqxab8d",
        e.target,
       "RIY4Nc1DlkQ_XKir9"
      )
      .then(
        (result) => {
          setStateMessage('Message sent!');
          setIsSubmitting(false);
          setTimeout(() => {
            setStateMessage(null);
          }, 5000); // hide message after 5 seconds
        },
        (error) => {
          setStateMessage('Something went wrong, please try again later');
          setIsSubmitting(false);
          setTimeout(() => {
            setStateMessage(null);
          }, 5000); // hide message after 5 seconds
        }
      );
    
    // Clears the form after sending the email
    e.target.reset();
  };


  const generateCaptcha = () => {
    const text = Math.random().toString(36).substring(2, 8);
    setCaptchaText(text);
    drawCaptcha(text);
  };

  const drawCaptcha = (text, func ) => {
   
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.font = '30px Arial';
    ctx.fillStyle = 'blue';
    ctx.fillText(text, 10, 30);
  
  };

  const handleChange = (e) => {

    setUserInput(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
     
    if (userInput === captchaText) {
      //alert('CAPTCHA verified');
      setUserInput('');
      sendEmail(e);
   

    } else {
       setUserInput('');
      setStateMessage('CAPTCHA verification failed');
    }
    generateCaptcha();
  };

  return (

   <div className='w-1/2 z-40 bg-slate-50'>
   
 <div className='m-auto items-center'>
 

    <div id="intro" className={ `2xl:w-full md:w-full xs:w-full  m-auto    flex flex-col    `} >

  
    
      
<form onSubmit={handleSubmit} className=' transition duration-700  flex flex-col  2xl:p-5 xs:p-5    hover:shadow-2xl border rounded ' >
  <Stack direction='column' gap={3}>
      

<Input   type="text" name="salut" placeholder='Title'  />
      <Input   type="text" name="name" placeholder='First Name' required  />
      <Input   type="text" name="lastname" placeholder='Last Name'  required />

      
      <Input  type="email" name="email" placeholder='Email' required  />
      
      <Input  type="text" name="pnumber" placeholder='Phone' required/>
      <Input  type="text" name="company" placeholder='Company'  />
    

      <TextField  type="text" name="address" placeholder='Address'  rows={4} />
      <Input id="country" type="text" name="country" placeholder='Select on map if not in KSA'  disabled  defaultValue={"Saudi Arabia"}  />
      <Input  type="text" name="position" placeholder='Position' />
      <TextField  name="message" placeholder='your message' multiline rows={4} required />
<canvas className='bg-gray-500 w-full ' ref={canvasRef} width={200} height={50} />
<div className='flex flex-col '>
<Button  className='my-2' variant='outlined' onClick={generateCaptcha}>Refresh CAPTCHA</Button>
      <TextField type="text" value={userInput} onChange={handleChange} />
      

</div>
<Button className='p-5' type="submit" variant='outlined' size='large' color='success'   disabled={isSubmitting} > Send</Button> 
     
      </Stack>
      {stateMessage && <p>{stateMessage}</p>}
    </form>

 
    
      
    </div>
   

      
    </div>
 </div>
    
    

  );
};

export default Captcha;