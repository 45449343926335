import { Parallax } from 'react-scroll-parallax';
const  Why =()=>{

    return(
      <Parallax translateY={[-80, 80]} >
        
        <div className={ ` w-full z-20    2xl:py-3   xs-p-1 2xl:col-span-12 md:col-span-12 xs:col-span-9   hover:text-slate-800      md:text-lg    `} >
       
    <div className="m-auto p-5  flex xl:flex-end md:flex-col  trasition-background duration-700 bg-slate-950 xl:w-1/3  hover:shadow-xl text-justify    " >



     

     <div className={ `text-slate-100 p-2 text-center flex flex-col 2xl:text-3xl  xs:text-xl    font-bold `} >
  
      ADVANCED SOLUTIONS is...
</div>
<div className="text-slate-100 p-1   m-auto md:text-sm font-thin  ">
A leading Solutions Provider of the electrical, instrumentation, automation & control system solutions in the Kingdom of Saudi Arabia.

</div>
</div>


   
          
        </div>
    </Parallax>
    )
}
export default Why; 