import { useState } from 'react';
import emailjs from '@emailjs/browser';
import { TextField , Input , Button} from '@mui/material';

import {Stack} from '@mui/material';
import { grey , orange, red} from '@mui/material/colors';
import World from '../components/world';
import ContactCard from '../components/contactCard';
import IconSet from '../components/iconset';
const ContactForm = ({theme}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stateMessage, setStateMessage] = useState(null);

  const selection = (e) => {
    //e.preventDefault();
    const country = document.getElementById('country')
    country.value = e.target.getAttribute('title')
    

  e.target.setAttribute("stroke" , "white")
 
  
  const siblings = e.target.parentElement.children
  for (let i = 0; i < siblings.length; i++) {
    if (siblings[i] !== e.target) {
      siblings[i].setAttribute("fill" , "white")
      siblings[i].setAttribute("stroke" , "grey")
    }else{
      siblings[i].setAttribute("fill" , "green-500")
      siblings[i].setAttribute("stroke" , "grey-500")
    }
  }


  
  }
    




  const sendEmail = (e) => {
    e.persist();
    e.preventDefault();
    setIsSubmitting(true);
    emailjs
      .sendForm(
        "service_moph2ff",
        "template_nqxab8d",
        e.target,
       "RIY4Nc1DlkQ_XKir9"
      )
      .then(
        (result) => {
          setStateMessage('Message sent!');
          setIsSubmitting(false);
          setTimeout(() => {
            setStateMessage(null);
          }, 5000); // hide message after 5 seconds
        },
        (error) => {
          setStateMessage('Something went wrong, please try again later');
          setIsSubmitting(false);
          setTimeout(() => {
            setStateMessage(null);
          }, 5000); // hide message after 5 seconds
        }
      );
    
    // Clears the form after sending the email
    e.target.reset();
  };
  return (
    <div id="intro" className={ `2xl:h-screen md:h-screen m-auto flex  items-center  text-slate-50 flex-col text-center 2xl:w-screen md:w-11/12  `} >
<div className={ `z-100 flex xs:flex-col 2xl:flex-row md:flex-row  p-1   items-center  bg-slate-50  `}>
  
    <World color="#ffffff"  style="hover:fill-slate-600  "onClick={selection}/>
      
<form className='top-0 transition duration-700  flex flex-col items-center 2xl:px-4 py-1 2xl:m-4 2xl:w-1/2 md:w-1/2 hover:shadow-2xl   ' onSubmit={sendEmail}>
  <Stack direction='column' gap={2} >
      

<Input   type="text" name="salut" placeholder='Title'  />
      <Input   type="text" name="name" placeholder='First Name' required  />
      <Input   type="text" name="lastname" placeholder='Last Name'  required />

      
      <Input  type="email" name="email" placeholder='Email' required  />
      
      <Input  type="text" name="pnumber" placeholder='Phone' required/>
      <Input  type="text" name="company" placeholder='Company'  />
    

      <TextField  type="text" name="address" placeholder='Address'  rows={4} />
      <Input id="country" type="text" name="country" placeholder='Select on map if not in KSA'  disabled  defaultValue={"Saudi Arabia"}  />
      <Input  type="text" name="position" placeholder='Position' />
      <TextField  name="message" placeholder='your message' multiline rows={4} required />

      <Button type="submit" variant='contained' color='success'  disabled={isSubmitting} > Send</Button>
      </Stack>
      {stateMessage && <p>{stateMessage}</p>}
    </form>
    <IconSet theme={theme} />
    <div className='2xl:flex md:flex xs:hidden 2xl:w-full'>
<ContactCard theme={theme } button={false} />
    </div>
    
      
    </div>
    
    </div>
    
  );
};
export default ContactForm;